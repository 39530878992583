<template>
  <div class="myPerformance">
    <div class="myPerformance-nav">
      <div
        :class="[{ active: curIndex == index }]"
        v-for="(item, index) in navList"
        @click="handelSelectNav(item, index)"
        :key="index"
      >
        <span>
          {{ item.label }}
          <img v-if="curIndex == index" src="./img/icon.png" alt="" />
        </span>
      </div>
    </div>
    <!-- 业绩总览 -->
    <div class="myPerformance-content">
      <div class="echart-content" v-if="curIndex == 0">
        <!-- 工时准时情况 -->
        <div class="common-style punctuality">
          <div class="title">工单准时情况</div>
          <div class="echarts" id="punctuality"></div>
        </div>
        <!-- 工时月度完成量 -->
        <div class="common-style completed-amount">
          <div class="title">工单月度完成量</div>
          <div class="echarts" id="completed-amount"></div>
        </div>
        <!-- 巡检月度完成量 -->
        <div class="common-style polling">
          <div class="title">巡检月度完成量</div>
          <div class="echarts" id="polling"></div>
        </div>
        <!-- 巡检时段偏好 -->
        <div class="common-style polling-preference">
          <div class="title">巡检时段偏好</div>
          <div class="echarts" id="polling-preference"></div>
        </div>
        <!-- 维保月度完成量 -->
        <div class="common-style maintenance">
          <div class="title">维保月度完成量</div>
          <div class="echarts" id="maintenance"></div>
        </div>
        <!-- 维保设备类型分布 -->
        <div class="common-style maintenance-type">
          <div class="title">维保设备类型分布</div>
          <div class="echarts" id="maintenance-type"></div>
        </div>
      </div>
      <div class="detail" v-else>
        <div class="task-item" @click="handelRouter('maintenanceWorkOrder')">
          <div class="item-l">维修工单</div>
          <div class="item-r">
            <div class="residue">
              剩余：{{ PerformanceDetails.workInfoRemainNum }}
            </div>
            <div class="finish">
              完成：{{ PerformanceDetails.workInfoFinishNum }}
            </div>
          </div>
        </div>
        <div class="task-item" @click="handelRouter('inspectionTasks')">
          <div class="item-l">巡检任务</div>
          <div class="item-r">
            <div class="residue">
              剩余：{{ PerformanceDetails.patrolRemainNum }}
            </div>
            <div class="finish">
              完成：{{ PerformanceDetails.patrolFinishNum }}
            </div>
          </div>
        </div>
        <div class="task-item" @click="handelRouter('deviceMaintenance')">
          <div class="item-l">维保任务</div>
          <div class="item-r">
            <div class="residue">
              剩余：{{ PerformanceDetails.maintenanceRemainNum }}
            </div>
            <div class="finish">
              完成：{{ PerformanceDetails.maintenanceFinishNum }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabBar :active="1"></tabBar>
  </div>
</template>

<script>
import tabBar from "./components/tabBar.vue";
import {
  getPerformanceOverview,
  getPerformanceDetails,
  getPatrolMonthFinishNum,
  getPatrolTimeLike,
  getMaintenanceMonthFinishNum,
  getMaintenanceTypeNum,
} from "./api.js";
import { debounce } from "@/utils/utils";
import {
  drawPieChart,
  drawLineChart,
  drawBarChart,
  drawPreferencePieChart,
  drawMaintenanceBarChart,
  drawRadarChart,
} from "./echart";
export default {
  name: "myPerformance",
  components: { tabBar },
  props: {},
  data() {
    return {
      curIndex: 0,
      punctuality: null,
      completedAmount: null,
      polling: null,
      pollingPreference: null,
      maintenance: null,
      maintenanceType: null,
      PerformanceDetails: {},
      navList: [
        {
          label: "业绩总览",
          value: 1,
        },
        {
          label: "业绩详情",
          value: 2,
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.initRequest();
    window.addEventListener("resize", this.resizeHandler, true);
  },
  methods: {
    initRequest() {
      this.getPerformanceOverview();
      this.getPatrolMonthFinishNum();
      this.getPatrolTimeLike();
      this.getMaintenanceMonthFinishNum();
      this.getMaintenanceTypeNum();
    },
    //跳转
    handelRouter(name) {
      this.$router.push({
        name,
      });
    },
    //获取工单业绩总览
    getPerformanceOverview() {
      this.$axios
        .get(getPerformanceOverview, {
          params: {
            phone: "18856218851",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { notOverTimeSum, overTimeSum, monthData } = res.data;
            const [notOverTime, overTime] = [
              { value: "", name: "正常工单" },
              { value: "", name: "超时工单" },
            ];
            notOverTime.value = notOverTimeSum;
            overTime.value = overTimeSum;
            const newArr = [notOverTime, overTime];
            this.punctuality = drawPieChart("punctuality", newArr);
            const xData = monthData.map((item) => item.month);
            const yData = monthData.map((item) => item.sum);
            this.completedAmount = drawLineChart(
              "completed-amount",
              xData,
              yData
            );
          }
        });
    },
    //获取巡检月度完成量
    getPatrolMonthFinishNum() {
      this.$axios
        .get(getPatrolMonthFinishNum, {
          params: {
            userId: "1223455",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            const xData = data.map((item) => item.date);
            const yData = data.map((item) => item.finishNum);
            this.polling = drawBarChart("polling", xData, yData);
          }
        });
    },
    //维保
    getMaintenanceMonthFinishNum() {
      this.$axios
        .get(getMaintenanceMonthFinishNum, {
          params: {
            userId: "1223455",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            const xData = data.map((item) => item.yyyyMM);
            const yData = data.map((item) => item.num);
            this.maintenance = drawMaintenanceBarChart(
              "maintenance",
              xData,
              yData
            );
          }
        });
    },
    //类型分布
    getMaintenanceTypeNum() {
      this.$axios
        .get(getMaintenanceTypeNum, {
          params: {
            userId: "1223455",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            const yData = data.map((item) => item.num);
            const maxValue = Math.max(...yData);
            const xData = data.map((item) => {
              return { name: item.type, max: maxValue };
            });

            this.maintenanceType = drawRadarChart(
              "maintenance-type",
              xData,
              yData
            );
          }
        });
    },
    //获取巡检时段偏好
    getPatrolTimeLike() {
      this.$axios
        .get(getPatrolTimeLike, {
          params: {
            userId: "1223455",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            const seriesData = data.map((item) => {
              return { value: item.num, name: item.time };
            });
            this.pollingPreference = drawPreferencePieChart(
              "polling-preference",
              seriesData
            );
          }
        });
    },
    //获取业绩详情
    getPerformanceDetails() {
      this.$axios
        .get(getPerformanceDetails, {
          params: {
            phone: "18856218851",
            userId: "1223455",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.PerformanceDetails = res.data;
          }
        });
    },
    resizeHandler() {
      return debounce(() => {
        this.punctuality.resize();
        this.completedAmount.resize();
        this.polling.resize();
        this.pollingPreference.resize();
        this.maintenance.resize();
        this.maintenanceType.resize();
      }, 100)();
    },
    //选择nav
    handelSelectNav(item, index) {
      this.curIndex = index;
      if (this.curIndex == 0) {
        this.initRequest();
      } else {
        this.getPerformanceDetails();
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>

<style scoped lang="less">
.myPerformance {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 48px;
  padding-bottom: 160px;
  .myPerformance-nav {
    position: sticky;
    top: 48px;
    width: 100%;
    height: 112px;
    padding: 0 54px;
    background: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      flex: 1;

      &.active {
        span {
          color: #333333;
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
        }
      }
      text-align: center;
      span {
        position: relative;
        display: inline-block;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }
      img {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -30%;
        right: -20%;
      }
      // .img_0 {
      //   top: -30%;
      //   right: -30%;
      // }
    }
  }
  .myPerformance-content {
    width: 100%;
    height: calc(100% - 112px);
    overflow: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 20px 30px;
    .detail {
      width: 100%;
      box-sizing: border-box;
      .task-item {
        min-height: 174px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 38px 70px 38px 42px;
        .item-l {
          display: flex;
          align-items: center;
          line-height: 40px;
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          &::before {
            content: "";
            display: inline-block;
            margin-right: 10px;
            width: 8px;
            height: 8px;
            background: #fe4416;
            border-radius: 50%;
          }
        }
        .item-r {
          .residue {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1692fe;
            line-height: 40px;
            margin-bottom: 18px;
          }
          .finish {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    .echart-content {
      width: 100%;
      box-sizing: border-box;
      .common-style {
        width: 100%;
        background: #ffffff;
        border-radius: 16px;
        padding: 20px 40px 30px 38px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
        .title {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
          margin-bottom: 8px;
        }
        .echarts {
          width: 100%;
          flex: 1;
        }
        &.punctuality {
          height: 400px;
        }
        &.completed-amount {
          height: 474px;
        }
        &.polling {
          height: 474px;
        }
        &.polling-preference {
          height: 512px;
        }
        &.maintenance {
          height: 474px;
        }
        &.maintenance-type {
          height: 474px;
        }
      }
    }

    .list {
      width: 100%;
      height: 264px;
      background: #ffffff;
      border-radius: 16px;
      padding: 32px 32px 24px 40px;
      box-sizing: border-box;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .status {
        position: absolute;
        right: 32px;
        top: 32px;
        text-align: right;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fe4416;
        line-height: 40px;
      }
      .item {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        .label {
          span {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            &:first-child {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
            }
          }
        }
        .time {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 0.5);
        }
      }
    }
  }
}
</style>
