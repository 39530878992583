import * as echarts from "echarts";

/// 饼图
export function drawPieChart(eleId, seriesData) {
  let option = {
    color: ["#449cf9", "#e84c4b"],
    tooltip: {
      trigger: "item",
    },

    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "50%"],
        itemStyle: {
          borderRadius: 8,
        },
        label: {
          show: true,
          position: "outer",
          overflow: "none",
          formatter({ name, value }) {
            return name + value;
          },
        },
        emphasis: {
          center: ["50%", "50%"],
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: seriesData,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//折线图
export function drawLineChart(eleId, xData, yData) {
  let option = {
    title: [
      {
        left: "right",
        top: "-5%",
        subtext: "单位：个",
        subtextStyle: {
          color: "#000000",
          fontSize: 12,
          fontWeight: "normal",
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      x: 30,
      y: 30,
      x2: 10,
      y2: 50,
      borderWidth: 1,
    },
    xAxis: {
      type: "category",
      data: xData,
      axisLabel: {
        rotate: 45,
        //x轴文字的配置
        show: true,
        interval: 0, //使x轴文字显示全
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "工单数量(个)",
        data: yData,
        type: "line",
        itemStyle: {
          normal: {
            color: "#FE4416",
            lineStyle: {
              color: "#FE4416",
            },
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//纵向柱状图带圆角
export function drawBarChart(eleId, xData, yData) {
  let option = {
    title: [
      {
        left: "right",
        top: "-5%",
        subtext: "单位：个",
        subtextStyle: {
          color: "#000000",
          fontSize: 12,
          fontWeight: "normal",
        },
      },
    ],
    grid: {
      x: 30,
      y: 30,
      x2: 10,
      y2: 50,
      borderWidth: 1,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: xData,
      axisLabel: {
        rotate: 45,
        //x轴文字的配置
        show: true,
        interval: 0, //使x轴文字显示全
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "完成巡检(个)",
        data: yData,
        type: "bar",
        barWidth: 20,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#159AFF",
            },
            {
              offset: 1,
              color: "rgba(21,154,255,0)",
            },
          ]),

          borderRadius: [16, 16, 0, 0],
        },
        showBackground: true,
        backgroundStyle: {
          color: "rgba(25,155,254,0.05)",
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//巡检时段偏好
export function drawPreferencePieChart(eleId, seriesData) {
  let option = {
    tooltip: {
      trigger: "item",
      formatter: (params, ticket, callback) => {
        return `<span
        style="
          display: inline-block;
          margin-right:10px;
          width: 10px;
          height: 10px;
          background: ${params.color};
          border-radius: 50%;
        "
      ></span>${params.data.name}&nbsp;&nbsp;${params.percent}%`;
      },
    },
    series: [
      {
        type: "pie",
        radius: "55%",
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: true,
          position: "outer",
          overflow: "none",
          formatter(param) {
            return param.name + "\n" + param.percent + "%";
          },
        },
        data: seriesData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

//维保
export function drawMaintenanceBarChart(eleId, xData, yData) {
  let option = {
    title: [
      {
        left: "right",
        top: "-5%",
        subtext: "单位：个",
        subtextStyle: {
          color: "#000000",
          fontSize: 12,
          fontWeight: "normal",
        },
      },
    ],
    grid: {
      x: 30,
      y: 30,
      x2: 10,
      y2: 50,
      borderWidth: 1,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: xData,
      axisLabel: {
        rotate: 45,
        //x轴文字的配置
        show: true,
        interval: 0, //使x轴文字显示全
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "完成维保(个)",
        data: yData,
        type: "bar",
        barWidth: 20,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#56D777",
            },
            {
              offset: 1,
              color: "rgba(86,215,119,0)",
            },
          ]),

          borderRadius: [16, 16, 0, 0],
        },
        showBackground: true,
        backgroundStyle: {
          color: "rgba(86,215,119,0.05)",
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//维保
export function drawRadarChart(eleId, xData, yData) {
  let option = {
    tooltip: {
      show: true,
    },
    radar: {
      radius: "65%",
      // shape: 'circle',
      indicator: xData,
    },
    series: [
      {
        name: "设备数量(个)",
        type: "radar",
        data: [
          {
            value: yData,
          },
        ],
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 绘制图表
function drawedChart(eleId, option) {
  let chart = null;
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId));
    chart.setOption(option);
  }
  return chart;
}
